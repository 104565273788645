import Navigation from "../components/Navigation";

function Menu() {
    return(
        <>
            <Navigation />
            <h1>This is Menu</h1>
        </>
    );
}

export default Menu;