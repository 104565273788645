import Navigation from "../components/Navigation";

function OrderOnline() {
    return(
        <>
            <Navigation />
            <h1>This is OrderOnline</h1>
        </>
    );
}

export default OrderOnline;