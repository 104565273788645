import Navigation from "../components/Navigation";
import LoginCompo from "../components/LoginCompo";

function Login() {
    return(
        <>
            <Navigation />
            <LoginCompo/>
        </>
    );
}

export default Login;