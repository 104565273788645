import Navigation from "../components/Navigation";
import BookingForm from "../components/BookingForm";

function Reservation() {
    return(
        <>
            <Navigation />
            <BookingForm/>
        </>
    );
}

export default Reservation;